(function($) {

$.fn.clickToggle = function(func1, func2) {
    var funcs = [func1, func2];
    this.data('toggleclicked', 0);
    this.click(function() {
        var data = $(this).data();
        var tc = data.toggleclicked;
        $.proxy(funcs[tc], this)();
        data.toggleclicked = (tc + 1) % 2;
    });
    return this;
};


//nav
$(function() {

    $('.level1btn li').click(function(){
      $(this).addClass('nav2active');
      $(this).siblings().removeClass('nav2active');
    });    

    //關於我們
    $("#menu-item-127").clickToggle(function(){
        $('.nav2').animate({left:"250px"},"slow");
        $(".nav3").animate({left:"0px"},"slow");
    },function(){
        $(".nav2").animate({left:"0px"},"slow");
    });

    //服務項目
    $("#menu-item-250").clickToggle(function(){
        $('.nav3').animate({left:"250px"},"slow");
        $(".nav2").animate({left:"0px"},"slow");
    },function(){
        $(".nav3").animate({left:"0px"},"slow");
    });

    
    $(".level2list ul li a").click(function(){
        $(this).parent().addClass('active');
        $(this).parent().siblings().removeClass('active');
        if(($(this).siblings(".sub-menu")).is(":hidden")){
        	$(this).siblings(".sub-menu").slideToggle("slow");
        }else{
          $(this).parent().removeClass("active");
          $(this).siblings(".sub-menu").slideUp("slow");
        }
      	$(this).parent().siblings().find(".sub-menu").slideUp();
    });

    $(".level3list ul li a").click(function(){
        $(this).parent().addClass('active');
        $(this).parent().siblings().removeClass('active');
        if(($(this).siblings(".sub-menu")).is(":hidden")){
          $(this).siblings(".sub-menu").slideToggle("slow");
        }else{
          $(this).parent().removeClass("active");
          $(this).siblings(".sub-menu").slideUp("slow");
        }
        $(this).parent().siblings().find(".sub-menu").slideUp();
    });

    
});

//mobilenav
$(function() { 
  $(".nav-box").click(function(){
      $("body").css("overflow-y","hidden");
      $(".mobile-nav").show();
      $(".mobile-nav").animate({right:"0px"},"slow");
      $(".pagemark").show();
      $(".pagemark").animate({opacity:0.7},"slow");
      $(".close").show();
      $(".close").animate({right:"250px"},"slow");
  });
  $(".close").click(function(){
      $("body").css("overflow-y","initial");
      $(".mobile-nav").animate({right:"-300px"},"slow");
      $(".pagemark").animate({opacity:0},"slow");
      $(".close").animate({right:"-50px"},"slow");
      $(".pagemark").hide(300);
      $(".mobile-nav").hide(300);
      $(".close").hide(300);
  });

  //關於我們
  //第一層動作
  $(".level1 #menu-item-65 a").click(function(){
      $(this).parent().addClass('active');
      $(this).parent().siblings().removeClass('active');
      if(($(this).siblings(".level2")).is(":hidden")){
          $(this).siblings(".level2").slideToggle("slow");
        }else{
          $(this).parent().removeClass("active");
          $(this).siblings(".level2").slideUp("slow");
        }
      $(this).parent().siblings().find(".level2").slideUp();
  });
  //第二層動作
  $("#menu-item-65 .level2 li").click(function(){
      $(this).addClass('active');
      $(this).siblings().removeClass('active');
      if(($(this).find(".level3")).is(":hidden")){
          $(this).find(".level3").slideToggle("slow");
          $(this).find(".level3").addClass('nav3active');
        }else{
          $(this).removeClass("nav3active");
          $(this).find(".level3").slideUp("slow");
        }
      $(this).siblings().find(".level3").slideUp();
  });

  //服務項目
  //第一層動作
  $(".level1 #menu-item-253 a").click(function(){
      $(this).parent().addClass('active');
      $(this).parent().siblings().removeClass('active');
      if(($(this).siblings(".level2")).is(":hidden")){
          $(this).siblings(".level2").slideToggle("slow");
        }else{
          $(this).parent().removeClass("active");
          $(this).siblings(".level2").slideUp("slow");
        }
      $(this).parent().siblings().find(".level2").slideUp();
  });
  //第二層動作
  $("#menu-item-253 .level2 li").click(function(){
      $(this).addClass('active');
      $(this).siblings().removeClass('active');
      if(($(this).find(".level3")).is(":hidden")){
          $(this).find(".level3").slideToggle("slow");
          $(this).find(".level3").addClass('nav3active');
        }else{
          $(this).removeClass("nav3active");
          $(this).find(".level3").slideUp("slow");
        }
      $(this).siblings().find(".level3").slideUp();
  });
});
$(function() {
  $('.slick-banner').slick({
    autoplay:true,
    prevArrow:false,
    nextArrow:false,
    autoplaySpeed:5000,
    infinite: true,
    dots: true
  });
  $('.slick-ad').slick({
    dots: true,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 6,
    autoplay:true,
    autoplaySpeed:2000,
    prevArrow:false,
    nextArrow:false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  });
  $('.event-slide').slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay:false,
    prevArrow:'<button type="button" data-role="none" class="slick-prev"></button>',
    nextArrow: '<button type="button" data-role="none" class="slick-next"></button>',
    autoplaySpeed:2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });
  $('.event-photos').lightGallery({
      thumbnail:true,
      animateThumb: false,
      showThumbByDefault: false,
      loadYoutubeThumbnail: false,
      youtubeThumbSize: 'default',
      loadVimeoThumbnail: false,
      vimeoThumbSize: 'thumbnail_medium',
      youtubePlayerParams: {
          modestbranding: 1,
          showinfo: 0,
          rel: 0,
          controls: 0
      },
      vimeoPlayerParams: {
          byline : 0,
          portrait : 0,
          color : 'A90707'
      }
  });
  $('.up').click(function(){
      $('html,body').animate({scrollTop:0},'slow');
  });
  $('.pagetop').click(function(){
      $('html,body').animate({scrollTop:0},'slow');
  });

  $('.con01').click(function(){
      $(this).siblings().removeClass('active');
      $(this).addClass('active');
      $('html,body').animate({scrollTop:$('.article01').offset().top},'slow');
  });
  $('.con02').click(function(){
      $(this).siblings().removeClass('active');
      $(this).addClass('active');
      $('html,body').animate({scrollTop:$('.article02').offset().top},'slow');
  });
  $('.con03').click(function(){
      $(this).siblings().removeClass('active');
      $(this).addClass('active');
      $('html,body').animate({scrollTop:$('.article03').offset().top},'slow');
  });
  $('.con04').click(function(){
      $(this).siblings().removeClass('active');
      $(this).addClass('active');
      $('html,body').animate({scrollTop:$('.article04').offset().top},'slow');
  });
  $('.con05').click(function(){
      $(this).siblings().removeClass('active');
      $(this).addClass('active');
      $('html,body').animate({scrollTop:$('.article05').offset().top},'slow');
  });
  $('.con06').click(function(){
      $(this).siblings().removeClass('active');
      $(this).addClass('active');
      $('html,body').animate({scrollTop:$('.article06').offset().top},'slow');
  });  

  $('#tiny-map').tinyMap({
    center: '新竹市中正路120號',
    zoom: 18,
    marker:[
    {
      addr:'新竹市中正路120號',
      icon:{
        url:'../../wp-content/themes/idipc-theme/dist/images/pin.png' ,
        scaledSize:[44,60]
      }
    }
    ]
  });

  $('#menu-item-101').find(".sub-menu").perfectScrollbar();
  $('#menu-item-278').find(".sub-menu").perfectScrollbar();
  $('#menu-item-174').find(".sub-menu").perfectScrollbar();
  $('#menu-item-175').find(".sub-menu").perfectScrollbar();
  $('.mobile-nav').find(".level1").perfectScrollbar();
});



})(jQuery);
